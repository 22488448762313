import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralServices } from '../../../common/general-services';
import { ServerAuthService } from '../../../common/server-auth';

@Component({
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

	null: any = null;
	true: boolean = true;
	false: boolean = false;
	form_submitted: boolean = false;
	center_name : any = null;

	is_profile_pic: any = false;

	found_contact:boolean = false;

	customer_data: any = {
		"contact":{
			"phone": null,
			"email":null,
			"first_name":null,
			"last_name":null,
			"gender": null,
			"date_of_birth":null,
			"img_data":null
		},
		"address":{
			"pincode":null,
			"address":null,
			"city":null,
			"state":null
		},
		"emergencyContact":{ 
			"first_name":null,
			"last_name":null,
			"phone":null,
			"relationship":null
		},
		"medical_history":null,
		"custom_fields":{
			"membership_id": null
		},
		"uuid": null
	}

	membership_id_enabled: boolean = false;
	medical_history_enabled: boolean = false;
	emergency_contact_enabled: boolean = false;


	emergency_contact_mandatory: boolean = true;
	medical_history_mandatory: boolean = true;
	membership_id_mandatory: boolean = true;


	photo_enabled : boolean = true;
	photo_mandatory: boolean = true;

	@ViewChild("file_input") file_input;
	@ViewChild("submit_btn") submit_btn;
	@ViewChild('ajax_screen') ajax_screen;
	
	constructor(
		private generalFunctions : GeneralServices,
		private route: ActivatedRoute,
		private router: Router,
		private http: ServerAuthService
		) { }

	ngOnInit() {
		this.route
			.params
			.subscribe(params => {
				if(params['id']){	
					this.getData(params["id"]);
				}
		});
	}
	
	isFilled(e){
		this.generalFunctions.isFilled(e);
	}

	autoIncreaseSize(e){
		var textarea = e.target,
			height = textarea.offsetHeight;
		textarea.style.height = "26px";
		textarea.style.height = textarea.scrollHeight+8+"px";
		textarea.style.maxHeight = textarea.scrollHeight+8+"px";
		this.isFilled(e);
	}

	getData(id){
		this.http.getLinkData(id)
			.subscribe(
				(success)=>{
					success = success;

					this.customer_data.contact.phone = success.data.phone;
						
					this.photo_enabled =  success.data.settings.img_data.enabled;
					this.photo_mandatory =  success.data.settings.img_data.mandatory;

					this.membership_id_enabled = success.data.settings.custom_fields.membership_id.enabled;
					this.medical_history_enabled = success.data.settings.medical_history.enabled;
					this.emergency_contact_enabled = success.data.settings.emergencyContact.enabled;


					this.emergency_contact_mandatory =   success.data.settings.emergencyContact.mandatory;
					this.medical_history_mandatory =   success.data.settings.medical_history.mandatory;
					this.membership_id_mandatory =   success.data.settings.custom_fields.membership_id.mandatory;

					this.center_name = success.data.center_name;
					this.customer_data.uuid = success.data.id;
					if(success.data.contact.data.length!=0){
						this.found_contact = true;
						this.customer_data.contact.email = success.data.contact.data.email;
						this.customer_data.contact.first_name = success.data.contact.data.first_name;
						this.customer_data.contact.last_name = success.data.contact.data.last_name;
						this.customer_data.contact.gender = success.data.contact.data.gender;
						this.customer_data.contact.date_of_birth = success.data.contact.data.date_of_birth;
					}
				},
				(error)=>{
					if(error.status_code == 404){
						this.generalFunctions.openSmallToast("This link does not exist!",3000,"error");
						this.router.navigate(['/formlink/expired']);
					}else{
						this.generalFunctions
							.openSmallToast(error.message,3000,"error");
					}
				}
			)
	}

	getLocation(e){
		this.generalFunctions.isFilled(e);
		if(e.target.classList.contains("ng-valid")){
			var pin = this.customer_data.address.pincode;
			this.http.getLocation(pin)
					.subscribe(
						(success)=>{
							this.customer_data.address.city = success.city;
							this.customer_data.address.state = success.state;
						}
					)
		}
	}

	onProfileChange(event){
		var files = event.currentFiles;
		if(files.length>0){
			this.is_profile_pic = true;
			var reader = new FileReader();
			reader.onloadend = (e)=>{
				this.customer_data["contact"]["img_data"] = reader.result;
			}
			reader.readAsDataURL(files[0]);
		}else{
			this.is_profile_pic = false;
			this.customer_data["contact"]["img_data"] = null;
		}
		
	}

	addNewCustomer(form,e){
		if(!form.valid){
			this.generalFunctions.openSmallToast("Fill all values with valid data",3000,"error");
		}else{
			var msg;
			if(!this.customer_data["contact"]["gender"]){
				msg="Select gender!"
			}

			if(!this.customer_data.uuid){
				msg="This link is expired!";
			}

			if(msg){
				this.generalFunctions.openSmallToast(msg,3000,"error");
				return false;
			}else{
				var obj = JSON.parse(JSON.stringify(this.customer_data));
				if(obj["contact"]["date_of_birth"]){
					var dob = this.generalFunctions.convertDateToISOFormat(obj["contact"]["date_of_birth"]);
					obj.contact.date_of_birth = dob["date"];
				}

				if(!this.membership_id_enabled){
					delete obj["custom_fields"];
				}
				if(!this.emergency_contact_enabled){
					delete obj["emergencyContact"];
				}

				if(this.emergency_contact_enabled&&!this.emergency_contact_mandatory){
					var touched = (obj["emergencyContact"]["last_name"] ||
							obj["emergencyContact"]["first_name"] ||
							obj["emergencyContact"]["phone"] ||
							obj["emergencyContact"]["relationship"]) ; 
					var untouched = (!obj["emergencyContact"]["last_name"] &&
							!obj["emergencyContact"]["first_name"] &&
							!obj["emergencyContact"]["phone"] &&					
							!obj["emergencyContact"]["relationship"])

					var alltouched = (obj["emergencyContact"]["last_name"] &&
							obj["emergencyContact"]["first_name"] &&
							obj["emergencyContact"]["phone"] &&
							obj["emergencyContact"]["relationship"]) ;

					if(touched&&!alltouched){
						this.generalFunctions.openSmallToast("Enter all values for emergency contact", 5000,"error");
						return false;
					}
					if(untouched){
						obj["emergencyContact"] = null;
					}
				}

				if((!this.photo_enabled)){
					delete obj["contact"]["img_data"];
				}
				if(this.photo_enabled&&this.photo_mandatory&&!this.customer_data['contact']['img_data']){
					this.generalFunctions.openSmallToast("Upload customer image!",3000,"error");
					return false;
				}

				if(!this.medical_history_enabled){
					delete obj["medical_history"];
				}

				this.submit_btn.nativeElement.classList.add("loading-ajax");
				this.ajax_screen.nativeElement
					.classList.add("active");

				this.http.sendLinkData(obj)
					.subscribe(
						(success)=>{
							this.form_submitted= true;
							this.submit_btn.nativeElement.classList.remove("loading-ajax");
							this.ajax_screen.nativeElement
										.classList.remove("active");
						},(error)=>{
							if(error.status == 413){
								this.generalFunctions.openSmallToast("Image size is too large", 5000 , "error");
							}else{
								this.generalFunctions
									.openSmallToast(error.message,3000,"error");
							}
							this.submit_btn.nativeElement.classList.remove("loading-ajax");
							this.ajax_screen.nativeElement
										.classList.remove("active");
						}
					)
			}
		}
	}

}
