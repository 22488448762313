import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GeneralServices } from './common/general-services';
import { ServerAuthService } from './common/server-auth';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Intercom } from 'ng-intercom';
import posthog from 'posthog-js';

declare var AwsWafIntegration: any; 

// tslint:disable-next-line: component-selector
@Component({ moduleId: module.id, selector: 'web-app', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit {
    include_center: any = null;
    currentUser: any = null;

    navLoader: any = true;
    isLoading = true;
    
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private generalFunctions: GeneralServices,
        private http: ServerAuthService,
        private readonly updates: SwUpdate,
        public intercom: Intercom) {

        const cookieCheck = JSON.parse(localStorage?.getItem("acceptCookies") ?? "true");
        posthog.init(environment.posthog_api_key, { api_host: 'https://us.posthog.com', disable_session_recording: !cookieCheck, "opt_in_site_apps": true })

        if (localStorage?.getItem('currentUser')) {
            this.include_center = this.generalFunctions.includeCenter();
            const currentUser = JSON.parse(
                localStorage.getItem("currentUser")
            ); 
            const localizationString = localStorage.getItem("localization");
            let localization;
            try {
                if (localizationString) {
                    localization = JSON.parse(localizationString);
                }
            } catch (error) {
                console.error("Error parsing localization JSON:", error);
            }
            this.generalFunctions.setV3Cookies();


            if (currentUser.roles) {
                const id = currentUser.client_id;
                this.http.getData('staff/' + id + this.include_center).subscribe(success => {
                    success = success;
                    const rolePermissions = JSON.stringify(success.data.roles);
                    this.currentUser = currentUser;
                    currentUser.intercomSecret = success.data.secret;
                    currentUser.roles = JSON.stringify(success.data.roles);
                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                }, error => {
                    this.generalFunctions.openToast(error.message, 5000, 'error');
                });
            }

            
            if (environment.production) {
                // Periodically check for updates
                setInterval(() => {
                    this.updates.checkForUpdate();
                }, 60000);
                this.generalFunctions.isSetAllCookies.subscribe(res => {
                    if(res) {
                        this.initializePostHog();
                    }
                })
                this.router.events.subscribe(event => {
                    if (event instanceof NavigationEnd) {
                        // tslint:disable-next-line: use-life-cycle-interface
                        (<any>window).ga('set', 'page', event.urlAfterRedirects); (<any>window).ga('send', 'pageview');
                    }
                });
            }
        }

        setTimeout(() => this.isLoading = false, 1000)
    }

    initializePostHog() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const currentCenter = JSON.parse(localStorage.getItem('currentCenter'));
        const client = JSON.parse(localStorage.getItem('client'));
        if (currentUser) {
            posthog.identify(currentUser?.email, {
                email: currentUser?.email,
                org: client?.client_url,
                center_name: currentCenter?.name,
                region_name: currentCenter?.region?.name,
            });
            posthog.group('company', client?.client_url, {
                company_name: currentUser?.businessName,
            });
        }
        // this.http.getData(`subscription?center_id=${center_id}`).subscribe(response => {
        //     const currentCenter = JSON.parse(localStorage.getItem('currentCenter'));
        //     const client = JSON.parse(localStorage.getItem('client'));
        //     posthog.identify(currentUser.email, {
        //         email: currentUser.email,
        //         org: client?.client_url,
        //         center_name: currentCenter?.name,
        //         region_name: currentCenter?.region?.name,
        //     });
        //     posthog.group('company', client?.client_url, {
        //         company_name: currentUser?.businessName,
        //     });
        // });
    }



    loadWafScript(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = environment.challenge;
          script.onload = () => {
            resolve();
          };
          script.onerror = (error) => {
            console.error('Error loading AWS WAF script:', error);
            reject(error);
          };
          document.head.appendChild(script);
        });
    }

    loadWaf() {
        this.loadWafScript()
        .then(() => {
            const wafToken = AwsWafIntegration.hasToken()
            if (typeof wafToken !== 'undefined') {
                if (!AwsWafIntegration.hasToken()) {
                  AwsWafIntegration.getToken()
                }
            }
        })
        .catch(error => {
            console.error('Failed to load WAF script:', error);
        });
    }


    ngOnInit() {
        this.loadWaf()
        if (this.currentUser == undefined) { } else {
            this.intercom.boot({
                app_id: environment.intercomAppId,
                email: this.currentUser.email,
                user_hash: this.currentUser.intercomSecret,
                widget: {
                    activator: '#intercom'
                }
            });
        }
    }
}
