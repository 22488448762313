import { Validator, AbstractControl, NG_VALIDATORS } from "@angular/forms";
import { Directive } from "@angular/core";

@Directive({
    selector: '[selectorValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: selectRequiredValidatorDirective,
        multi: true
    }]
})

export class selectRequiredValidatorDirective implements Validator {
    validate(control: AbstractControl) : {[key: string]: any} | null {
        return control.value === '0' ? {'defaultSelected': true} : null;
    }
}