import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './no-access.component.html',
    styleUrls: ['./no-access.component.scss']
})

export class NoAccessComponent implements OnInit {
    
    constructor() { }

    ngOnInit(): void { }
}