import {Component, OnInit} from "@angular/core";
import {Router, ActivatedRoute} from "@angular/router";
import {ServerAuthService} from "../../../common/server-auth";
import {GeneralServices} from "../../../common/general-services";

@Component({templateUrl: "./verify-email.component.html", styleUrls: ["./verify-email.component.css"]})
export class VerifyEmailComponent implements OnInit {
  // @ViewChild('ajax_loader') ajax_loader;

  constructor(private route : ActivatedRoute, private router : Router, private http : ServerAuthService, private generalFunctions : GeneralServices) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.verifyEmail(params["id"]);
      }
    });
  }

  verifyEmail(id) {
    this.http.verifyEmail(id).subscribe(success => {      
      const response = success.auth;
      
      let token = response?.access_token,
        center_id = response?.centers[0]?.uuid || null,
        businessName = response?.client_name,
        client_id = response?.logged_user.uuid,
        currency = response?.currency,
        country = response?.country,
        name = response?.logged_user.first_name,
        phone = response?.logged_user.phone,
        setup_completed = response?.logged_user.setup_completed,
        onboarding_completed = response?.logged_user.onboarding_completed,
        setup_details_completed = response?.logged_user.setup_details_completed,
        email = response?.logged_user.email, 
        role = JSON.stringify(response?.logged_user.roles) || null,
        is_verified = response?.logged_user.is_verified;
      
      localStorage.setItem("currentUser", JSON.stringify({
        appId: 3,
        email: email,
        token: token,
        center_id: center_id,
        name: name,
        country: country.iso_code_2,
        currency: currency.symbol,
        client_id: client_id,
        phone: phone,
        businessName: businessName,
        roles: role,
        setup_completed: setup_completed,
        onboarding_completed: onboarding_completed,
        setup_details_completed,
        intercomSecret: response?.logged_user.secret,
        is_verified: is_verified
      }));

      if (!onboarding_completed) {
        this.router.navigate(["client/create-account"]);
      } else {
          this.router.navigate(["client/dashboard"]);
          this.generalFunctions.openToast("Email verified successfully", 5000, "success");
      }
    }, error => {
      if (error.error.message == "Wrong token or your email is already verified.") {
        this.generalFunctions.openToast("Email already verified", 5000, "error");
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if(currentUser){
          currentUser.is_verified = true;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
        }
        this.router.navigate(["/login"], {
          queryParams: {
            setupCompleted: true
          }
        });
      } else {
        this.router.navigate(["/login"]);
        this.generalFunctions.openToast(error.message, 5000, "error");
      }
    });
  }
}
