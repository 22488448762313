import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/guard-service';
import { NotfoundComponent } from './components/not-found';
import { HomeComponent } from './components/home';
import { SignupComponent } from './components/home/signup';
// import { LoginComponent } from './components/home/login';
import { VerifyEmailComponent } from './components/home/verify-email';
import { VerifyStaffComponent } from './components/home/verify-staff';
import { ContactFormComponent } from './components/home/contact-form';
import { NotFoundFormlinkComponent } from './components/not-found-formlink';
import { AuthLoginComponent } from './components/home/auth-login';
import { AuthForgotPasswordComponent } from './components/home/auth-forgot-password';

/* AUTHENTICATION FILE FOR CRM ACCESS */

/* DIFFERENT ROUTE FILES */

/* COMPONENT LINKED TO ROUTES */

const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: '/client',
        pathMatch: 'full'
      }, {
        path: 'login',
        component: AuthLoginComponent
      }, {
        path: 'signup',
        component: SignupComponent
      }, {
        path: 'email/verify/:id',
        component: VerifyEmailComponent
      }, {
        path: 'email/staff/verify/:id',
        component: VerifyStaffComponent
      }, {
        path: 'contacts/formlink/:id',
        component: ContactFormComponent
      }, {
        path: 'formlink/expired',
        component: NotFoundFormlinkComponent
      }
    ]
  }, {
    path: 'password/reset/:id',
    component: AuthForgotPasswordComponent
  }, {
    path: 'client',
    loadChildren: () => import('./auth.module').then(m => m.AuthModule),

    canActivate: [AuthGuard]
  }, {
    path: 'notfound',
    component: NotfoundComponent
  }, {
    path: '**',
    component: NotfoundComponent
  }
];

export const CustomRoutes = RouterModule.forRoot(appRoutes, {});
