import { inject } from "@angular/core";
import { PosComponent } from "../../components/main/content/pos";
import {MarketingAutomationsDetailComponent} from '../../components/main/content/marketing/marketing-automations-detail/marketing-automations-detail.component';
import { GeneralServices } from "../general-services";
import { Intercom } from "ng-intercom";
import {
  Router,
  CanActivateFn,
  CanDeactivateFn
} from "@angular/router";

import { environment } from "environments/environment";

export const AuthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router)
  const intercom = inject(Intercom)

  if (localStorage.getItem("currentUser")) {
    intercom.boot({
      app_id: environment.intercomAppId,
      email: JSON.parse(localStorage.getItem("currentUser")).email,
      name: JSON.parse(localStorage.getItem("currentUser")).name,
      user_hash: JSON.parse(localStorage.getItem("currentUser")).intercomSecret,
      widget: {
        activator: "#intercom"
      }
    });
    return true;
  }

  if (state.url == "/client" || state.url == "/client/dashboard" || state.url.includes('restricted')) {
    router.navigate(["login"]);
  } else {
    router.navigate(["login"], {
      queryParams: {
        returnUrl: state.url
      }
    });
  }
  return false;
}

export const DestroyPOS: CanDeactivateFn<PosComponent> = (component, currentRoute, currentState, nextState: any) => {
  if (component && component.isContactSelected && !component.is_transaction_successful) {
    component.pop_up_for = "escape";
    component.routeLink = nextState.url;

    if (component.popUpClose == true) {
      return true;
    } else {
      return false;
    }

  } else {
    return true;
  }
}

export const UnsavedChangesGaurd: CanDeactivateFn<MarketingAutomationsDetailComponent> = (component, currentRoute, currentState, nextState: any) => {
  if (component?.isUnSavedChanges) {
    return confirm("Are you sure you want to leave? All your unsaved changes will be lost.");
  }
  return true
}

export const AccessGuard: CanActivateFn = () => {
  const grantAccess: boolean = true;
  const generalFunctions = inject(GeneralServices)
  const router = inject(Router)
  if (!JSON.parse(localStorage.getItem("currentUser"))) {
    generalFunctions.openToast("Please login again", 3000, "success");
    router.navigate(["login"]);

    return false;
  }

  if (JSON.parse(localStorage.getItem("currentUser")).roles) {
    return grantAccess;
  }

  if (!grantAccess) {
    generalFunctions.openToast("You are not authorized for this module!", 3000, "error");
    return grantAccess;
  }
  return grantAccess;
}
