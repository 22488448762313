import { Component, OnInit, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralServices } from '../../../common/general-services';
import { ServerAuthService } from '../../../common/server-auth';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

	data: any = {
		"email": null,
		"token": null,
		"password": null,
		"password_confirmation": null
	}

	password_changed: boolean = false;
	error: any = null;
    	@ViewChild('ajax_loader') ajax_loader;

	constructor(
		private router : Router,
		private route: ActivatedRoute,
		private generalFunctions: GeneralServices,
		private http: ServerAuthService
	) { }

	ngOnInit() {
		this.route
			.queryParams
			.subscribe(params => {
					let email = params["email"];
					this.data["email"] = decodeURI(email);
				}
			)
		this.route
			.params
			.subscribe(params => {
					this.data["token"] = params['id'];
				}
			)
	}

	isFilled(e){
		this.generalFunctions.isFilled(e);
	}

	resetPassword(form,e) {
		if(!form.valid){
			e.target.classList.add("submit-false");
			this.error = "Enter both fields before you proceed!"
		}else{
			e.target.classList.remove("submit-false");
			if(this.data.password != this.data.password_confirmation){
				this.error = "Match both the password fields";
				return false;
			}
			this.error = null;

			var btn = e.target.querySelectorAll("[type='submit']")[0];
			btn.classList.add("loading-ajax");
			this.ajax_loader.nativeElement
						.classList.add("active");

			this.http.resetPassword(this.data)
				.subscribe(
					(success)=>{
						this.password_changed = true;
						btn.classList.remove("loading-ajax");
						this.ajax_loader.nativeElement
							.classList.remove("active");
					},(error)=>{
						this.error = error.message;
						btn.classList.remove("loading-ajax");
						this.ajax_loader.nativeElement
							.classList.remove("active");
					}
				)
		}
	}


}
