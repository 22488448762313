import "@angular/compiler";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableProdMode } from "@angular/core";
import { environment } from "./environments/environment";
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from "./app/app.module";
import posthog from "posthog-js";

if (environment.production) {
  enableProdMode();
  Sentry.init({
    enabled: true,
    dsn: "https://62c92e0925584a9d94384eb214e47ec7@o443412.ingest.sentry.io/5958818",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", environment.baseUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new posthog.SentryIntegration(posthog, 'bookee-apps', 5454988),
      new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: environment.env_name,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule);