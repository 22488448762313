import { Component, OnInit, ViewChild } from '@angular/core';
import { Router,  ActivatedRoute } from '@angular/router';
import { ServerAuthService } from '../../../common/server-auth';
import { GeneralServices } from '../../../common/general-services';

@Component({
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

	client: any = {
		"username": null,
		// "phone": null,
		"email": null,
		"password": null,
		// "password_confirmation": null
	}

	error: any;
	sign_up_done: boolean = false;
	password_flag: boolean = false;

    	@ViewChild('ajax_loader') ajax_loader;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private generalFunctions: GeneralServices,
		private http : ServerAuthService

	) { }

	ngOnInit() {
	}

	isFilled(e){
		this.generalFunctions.isFilled(e);
	}

	signupClient(form,e){
		if(!form.valid){
			e.target.classList.add("submit-false");
			this.error = "Enter valid values before you proceed!"
		}else{
			e.target.classList.remove("submit-false");
			// var password = this.client["password"];
			// var confirm_password = this.client["password_confirmation"];
			// if(password != confirm_password){
			// 	this.error = "Match the password!"
			// }else{
				this.client["first_name"] = this.client.username.split(" ")[0];
				this.client["last_name"] = this.client.username.split(" ")[1];
				//HTTP REQUEST HANDLER

				var btn = e.target.querySelectorAll("[type='submit']")[0];
				btn.classList.add("loading-ajax");
				this.ajax_loader.nativeElement
							.classList.add("active");
				this.error = null;
				this.http.signup(this.client)
					.subscribe(
						(success)=>{
							success = success;
							this.generalFunctions
								.openToast("Verification mail has been sent to your email-id!",3000,"success");
							this.sign_up_done = true;
							btn.classList.remove("loading-ajax");
							this.ajax_loader.nativeElement
										.classList.remove("active");
						},(error)=>{
							this.error= error.message;
							btn.classList.remove("loading-ajax");
							this.ajax_loader.nativeElement
										.classList.remove("active");
						});

			// }
		}
	}

	resendSignUpLink(e){
		e.target.classList.add("loading-ajax");
		this.ajax_loader.nativeElement
					.classList.add("active");
		this.error = null;
		var obj = {};
		obj["email"] = this.client.email;
		this.http.resendEmailVerification(obj)
			.subscribe(
				(success)=>{
					success = success;
					this.generalFunctions
						.openToast("Verification mail has been sent to your email-id!",3000,"success");
					e.target.classList.remove("loading-ajax");
                         this.ajax_loader.nativeElement.classList.remove("active");
				},(error)=>{
					this.error= error.message;
					e.target.classList.remove("loading-ajax");
					this.ajax_loader.nativeElement
								.classList.remove("active");
				});
	}

}
