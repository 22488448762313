import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute,
	NavigationError, NavigationCancel, RoutesRecognized, Event } from '@angular/router';

@Component({
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	route_container_side: string = null;

	hide_strip: boolean = false;

	activeUrl: string = "right";

	returnUrl: any ;

	constructor(
		private router: Router,
		private route : ActivatedRoute
	) {
		router.events.subscribe( (event:Event) => {
			if(event instanceof NavigationEnd) {
				this.activeUrl = event.url;
				if(this.activeUrl.indexOf("formlink") !== -1){
					this.route_container_side = null;
					this.hide_strip = true;
				}else if(this.activeUrl.indexOf("signup") !== -1){
					this.route_container_side = 'left';
					this.hide_strip = false;
				}else if(this.activeUrl.indexOf("login") !== -1){
					this.route_container_side = 'right';
					this.hide_strip = false;
				}else if(this.activeUrl.indexOf("password/reset") !== -1){
					this.route_container_side = 'center';
					this.hide_strip = false;
				}else if(this.activeUrl.indexOf("email/staff/verify") !== -1){
					this.route_container_side = 'center';
					this.hide_strip = false;
				}
			}
		});
	}

	ngOnInit() {


		if (localStorage.getItem('currentUser')) {
			//GET SAVED CLIENT DETAILS
			var savedClient = JSON.parse(localStorage.getItem('currentUser'));
			this.router.navigate(['client']);
		}else{
			this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
		}
	}



}
