import { Component, OnInit, ViewChild } from '@angular/core';
import { Router,  ActivatedRoute } from '@angular/router';
import { ServerAuthService } from '../../../common/server-auth';
import { GeneralServices } from '../../../common/general-services';

@Component({
  selector: 'app-auth-forgot-password',
  templateUrl: './auth-forgot-password.component.html',
  styleUrls: ['./auth-forgot-password.component.scss']
})
export class AuthForgotPasswordComponent implements OnInit {

    data: any = {
		"email": null,
		"token": null,
		"password": null,
		"password_confirmation": null
    }
    invalid: boolean;

	notMatch: boolean = false;

	password_changed: boolean = false;
	error: any = null;
    	@ViewChild('ajax_loader') ajax_loader;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private generalFunctions: GeneralServices,
		private http: ServerAuthService
	) { }

	ngOnInit() {
		this.route
			.queryParams
			.subscribe(params => {
					let email = params["email"];
					this.data["email"] = decodeURI(email);
				}
			)
		this.route
			.params
			.subscribe(params => {
					this.data["token"] = params['id'];
				}
            )

        this.validateToken();
            // this.invalid = true;
            // this.router.navigate(['/login'], { queryParams: { invalidToken: true } });
    }

    navigateLogin(){
        this.router.navigate(["/login"]);
    }


    validateToken() {
        console.log('this.data', this.data)
        const reqObj = {
            token: this.data.token
        }
        this.http.linkValidation(reqObj).subscribe((response) => {
            console.log('response', response)

            if (!response.status) {
                this.invalid = true;
                this.router.navigate(['/login'], { queryParams: { invalidToken: true } });
            }
        }, err => {
                this.invalid = true;
                this.router.navigate(['/login'], { queryParams: { invalidToken: true } });
        })
    }

	isFilled(e) {
		this.generalFunctions.isFilled(e);
	}

	resetPassword() {

			if(this.data.password != this.data.password_confirmation){
					 this.error = "Match both the password fields";
					 this.notMatch = true;
					return false;
			}



			this.http.resetPassword(this.data)
				.subscribe(
					(success) => {
                        this.password_changed = true;
                        this.router.navigate(['/login'], { queryParams: { passwordReset: true } });
                        },
                        (error) => {
                        this.generalFunctions.openToast(error.message,3000,'error');
					}
				)

	}

	formValidate(field){
		let isValid = true;
		switch(field){
			case 'confirm-password' : {
				isValid = (!(this.data.password == this.data.password_confirmation)) ? false : true;
				// this.validationFields.password = isValid;
				break;
			}
			default:
			break;
		}
		return !isValid;
	}



}
