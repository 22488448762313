/* BASIC IMPORTS FOR APP MODULE */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomRoutes } from './app.routes';
// import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ImageCropperModule } from 'ngx-image-cropper';
// import { Md2Module }  from 'md2';
import { Md2Module, NoConflictStyleCompatibilityMode } from 'angular-md2';
import { environment } from '../environments/environment';
import { RouterModule } from '@angular/router';
import { IntercomModule } from 'ng-intercom';
// import * as Raven from 'raven-js';
import { DragDropModule } from '@angular/cdk/drag-drop';

/* IMPORT SERVICES AND PIPES FOR MODULE */
import { ServerAuthService, WafInterceptor } from './common/server-auth';
import { GeneralServices } from './common/general-services';

/* IMPORT DIRECTIVES */
import { selectRequiredValidatorDirective } from './shared/select-required-validator-directive';

/* COMPONENT CATEGORY 0 */
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home';
import { SignupComponent } from './components/home/signup';
import { LoginComponent } from './components/home/login';
import { VerifyEmailComponent } from './components/home/verify-email';
import { VerifyStaffComponent } from './components/home/verify-staff';
import { ContactFormComponent } from './components/home/contact-form';
import { NotFoundFormlinkComponent } from './components/not-found-formlink';
import { ForgotPasswordComponent } from './components/home/forgot-password';
import { NotfoundComponent } from './components/not-found';
import { AuthLoginComponent } from './components/home/auth-login';
import { AuthForgotPasswordComponent } from './components/home/auth-forgot-password';
import { RecaptchaModule } from 'ng-recaptcha';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { ChargebeeJsAngularWrapperModule } from '@chargebee/chargebee-js-angular-wrapper';
import { CookieBannerComponent } from './components/home/cookie-banner/cookie-banner.component';
/* CONTENT COMPONENT */

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MessageService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

export function playerFactory(): any {  
  return player;
}

@NgModule({
  imports: [
    BrowserModule, HttpClientModule, CustomRoutes, BrowserAnimationsModule,
    // NoopAnimationsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    Md2Module,
    NoConflictStyleCompatibilityMode,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    DragDropModule,
    ToastModule,
    ImageCropperModule,
    // RouterModule.forRoot([], { useHash: !environment.production }),
    RouterModule.forRoot([], { useHash: true }),
    IntercomModule.forRoot({
      appId: environment.intercomAppId, // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`,
      alignment: "right"
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ChargebeeJsAngularWrapperModule,
    LottieModule.forRoot({ player: playerFactory }), 
  ],
  declarations: [
    AppComponent,
    NotfoundComponent,
    HomeComponent,
    ContactFormComponent,
    NotFoundFormlinkComponent,
    VerifyEmailComponent,
    ForgotPasswordComponent,
    SignupComponent,
    LoginComponent,
    VerifyStaffComponent,
    selectRequiredValidatorDirective,
    AuthLoginComponent,
    AuthForgotPasswordComponent,
    NoAccessComponent,
    CookieBannerComponent
  ],
  providers: [
    ServerAuthService,
    GeneralServices,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WafInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
