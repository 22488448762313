import {Component, OnInit, ViewChild} from "@angular/core";
import {Router, ActivatedRoute} from "@angular/router";
import {ServerAuthService} from "../../../common/server-auth";
import {GeneralServices} from "../../../common/general-services";
import posthog from 'posthog-js';
import { environment } from '../../../../environments/environment';
import { AccountStatus } from 'app/constants';

@Component({templateUrl: "./login.component.html", styleUrls: ["./login.component.css"]})
export class LoginComponent implements OnInit {
  client: any = {
    email: null,
    password: null
  };
  reset_password: any = {
    email: null
  };
  returnUrl: string;
  forgot_password: boolean = false;
  link_sent: boolean = false;
  error: any = {
    login: null,
    reset_password: null
  };

  @ViewChild("ajax_loader")ajax_loader;

  constructor(private route : ActivatedRoute, private router : Router, private generalFunctions : GeneralServices, private http : ServerAuthService) {}

  ngOnInit() {}

  isFilled(e) {
    this.generalFunctions.isFilled(e);
  }

  loginClient(form, e) {
    if (!form.valid) {
      e.target.classList.add("submit-false");
      this.error.login = "Enter valid values before you proceed!";
    } else {
      e.target.classList.remove("submit-false");
      localStorage.removeItem("currentUser");
      this.generalFunctions.clearCookies();
      this.error.login = null;

      var btn = e.target.querySelectorAll("[type='submit']")[0];
      btn.classList.add("loading-ajax");
      this.ajax_loader.nativeElement.classList.add("active");
      console.log("login api start")
      this.http.login(this.client.email, this.client.password, "").subscribe(success => {
        success = success;
        console.log("login api success")
        let token = success && success.access_token,
          center_id = success && success.center_id,
          client_id = success && success.logged_user.uuid,
          currency = success && success.currency,
          country = success && success.country,
          businessName = success && success.client_name,
          name = success && success.logged_user.first_name + " " + success.logged_user.last_name,
          setup_completed = success && success.logged_user.setup_completed,
          onboarding_completed = success && success.logged_user.onboarding_completed,
          setup_details_completed = success && success.logged_user.setup_details_completed,
          phone = success && success.logged_user.phone;

        var role = null;
        if (success.logged_user.is_super_admin == 0) {
          role = JSON.stringify(success.logged_user.roles);
        }
        var localizedData = success.localization;
        localStorage.setItem("localization", JSON.stringify(localizedData));
        localStorage.setItem("currentUser", JSON.stringify({
          appId: 3,
          id: success?.logged_user.id,
          email: this.client.email,
          token: token,
          center_id: center_id,
          businessName: businessName,
          name: name,
          client_id: client_id,
          roles: role,
          setup_completed: setup_completed,
          onboarding_completed: onboarding_completed,
          setup_details_completed: setup_details_completed,
          intercomSecret: success.logged_user.secret
        }));
        console.log("initialize post hog")
        this.initializePostHog()
        this.generalFunctions.setV3Cookies()
        if (onboarding_completed == true) {
          this.router.navigate(["client/dashboard"]);
        } else {
          this.router.navigate(["client/create-account"]);
        }
      }, error => {
        this.error.login = error.message;
        btn.classList.remove("loading-ajax");
        this.ajax_loader.nativeElement.classList.remove("active");
      });
    }
  }

  initializePostHog() {
    const currentCenter = JSON.parse(localStorage.getItem('currentCenter'));
    this.http.getData(`subscription?center_id=${currentCenter.id}`)
      .subscribe(response => {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const currentCenter = JSON.parse(localStorage.getItem('currentCenter'));
        const client = JSON.parse(localStorage.getItem('client'));
        posthog.identify(currentUser.email, {
          email: currentUser.email,
          org: client.client_url,
          center_name: currentCenter?.name,
          region_name: currentCenter?.region?.name,
        });
        posthog.group('company', client.client_url, {
          company_name: currentUser?.businessName,
          subscription_status: Object.keys(AccountStatus).find(key => AccountStatus[key] === response.subscription.status),
          plan_id: response?.subscription?.plan.chargebee_id
        });
      })
  }

  resetPassword(form, e) {
    if (!form.valid) {
      e.target.classList.add("submit-false");

      this.error.reset_password = "Enter valid values before you proceed!";
    } else {
      e.target.classList.remove("submit-false");

      this.error.reset_password = null;
      var btn = e.target.querySelectorAll("[type='submit']")[0];
      this.resetPasswordHttp(e);
    }
  }

  resetPasswordHttp(e) {
    var btn = e.target.nodeName == "BUTTON"
      ? e.target
      : e.target.querySelectorAll("[type='submit']")[0];
    btn.classList.add("loading-ajax");
    this.ajax_loader.nativeElement.classList.add("active");

    this.http.resetPasswordLink(this.reset_password).subscribe(success => {
      var msg = "Password reset email successfully sent!";
      this.generalFunctions.openToast(msg, 5000, "success");
      this.forgot_password = false;
      this.link_sent = true;
      btn.classList.remove("loading-ajax");
      this.ajax_loader.nativeElement.classList.remove("active");
    }, error => {
      this.error.reset_password = error.message;
      btn.classList.remove("loading-ajax");
      this.ajax_loader.nativeElement.classList.remove("active");
    });
  }
}
